import { Button, Icon } from "@repo/ui";
import { useEffect, useState } from "react";

type HolidayT = {
  start: Date;
  end: Date;
  message: string;
};

const DelayBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [activeHoliday, setActiveHoliday] = useState<HolidayT>();

  useEffect(() => {
    const dismissed = JSON.parse(
      localStorage.getItem("holiday-banner") || "{}"
    );

    const now = new Date();

    const holidays: HolidayT[] = [
      {
        start: new Date(2024, 12, 27),
        end: new Date(2025, 0, 1),
        message:
          "EFT's will not be processed on New Year's Day. All requests after 4pm MST on Dec 31 will be processed Jan 2nd.",
      },
      {
        start: new Date(2024, 2, 29), // Mar 29th
        end: new Date(2024, 2, 30), // Mar 30th
        message:
          "EFT's will not be processed on Good Friday. All requests after 4pm MST on Mar 28 will be processed Mar 30th.",
      },
      {
        start: new Date(2024, 3, 1), // Apr 1st
        end: new Date(2024, 3, 2), // Apr 2nd
        message:
          "EFT's will not be processed on Easter Monday. All requests after 4pm MST on Mar 31 will be processed Apr 2nd.",
      },
      {
        start: new Date(2024, 4, 20), // May 20th
        end: new Date(2024, 4, 21), // May 21st
        message:
          "EFT's will not be processed on Victoria Day. All requests after 4pm MST on May 19 will be processed May 21st.",
      },
      {
        start: new Date(2024, 5, 24), // Jun 24th
        end: new Date(2024, 5, 25), // Jun 25th
        message:
          "EFT's will not be processed on Saint-Jean-Baptiste Day. All requests after 4pm MST on Jun 23 will be processed Jun 25th.",
      },
      {
        start: new Date(2024, 6, 1), // Jul 1st
        end: new Date(2024, 6, 2), // Jul 2nd
        message:
          "EFT's will not be processed on Canada Day. All requests after 4pm MST on Jun 30 will be processed Jul 2nd.",
      },
      {
        start: new Date(2024, 7, 5), // Aug 5th
        end: new Date(2024, 7, 6), // Aug 6th
        message:
          "EFT's will not be processed on Civic Holiday. All requests after 4pm MST on Aug 4 will be processed Aug 6th.",
      },
      {
        start: new Date(2024, 8, 2), // Sep 2nd
        end: new Date(2024, 8, 3), // Sep 3rd
        message:
          "EFT's will not be processed on Labour Day. All requests after 4pm MST on Sep 1 will be processed Sep 3rd.",
      },
      {
        start: new Date(2024, 8, 27), // Sept 27th
        end: new Date(2024, 9, 1), // Oct 1st
        message:
          "EFT's will not be processed on National Day for Truth and Reconciliation. All requests after 4pm MST on Sept 29 will be processed Oct 1st.",
      },
      {
        start: new Date(2024, 9, 9),
        end: new Date(2024, 9, 14),
        message:
          "EFT's will not be processed on Thanksgiving. All requests after 4pm MST on Oct 13 will be processed Oct 15th.",
      },
      {
        start: new Date(2024, 10, 6),
        end: new Date(2024, 10, 11),
        message:
          "EFT's will not be processed on Remembrance Day. All requests after 4pm MST on Nov 10 will be processed Nov 12th.",
      },
      {
        start: new Date(2024, 11, 20),
        end: new Date(2024, 11, 26),
        message:
          "EFT's will not be processed on Christmas Holidays. All requests after 4pm MST on Dec 24 will be processed Dec 27th.",
      },
    ];

    const isHoliday = holidays.some((holiday) => {
      if (now >= holiday.start && now < holiday.end) {
        setActiveHoliday(holiday);

        if (dismissed[formatDate(holiday.start)] !== true) {
          setBannerMessage(holiday.message);
          return true;
        }
      }
      return false;
    });

    if (isHoliday) {
      setIsVisible(true);
    }
  }, []);

  const dismissBanner = () => {
    if (activeHoliday) {
      const dismissed = JSON.parse(
        localStorage.getItem("holiday-banner") || "{}"
      );
      dismissed[formatDate(activeHoliday.start)] = true;
      localStorage.setItem("holiday-banner", JSON.stringify(dismissed));
      setIsVisible(false);
    }
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      data-testid="delay-banner"
      className="Delay-banner fixed top-20 z-50 mr-4 max-w-5xl rounded-lg border border-[#ffe58f] bg-[#fffbe6] p-4 shadow-xl md:top-4 md:mx-[60px]"
    >
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-row items-center gap-4">
          <Icon icon="warning-fill" iconStyle="fill-yellow-300" size={"24"} />

          <div className="text-basefont-medium text-gray-900">
            {bannerMessage}
          </div>
        </div>

        <Button
          customIconSize="20"
          icon="x"
          onClick={dismissBanner}
          color="transparent"
          customIconStyles="fill-gray-900"
          data-testid="dismiss-button"
        />
      </div>
    </div>
  );
};

export default DelayBanner;
