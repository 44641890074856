import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { Icon } from "../../Icon";
import { Loader } from "../../Loader";

export type PropsT = {
  options?: { value: string; label: string; description?: string }[];
  value?: { value: string; label: string } | null;
  onChange?: (value: { value: string; label: string }) => void;
  disabled?: boolean;
  placeholder?: string;
  wide?: boolean;
  error?: string;
  label?: string;
  hint?: string;
  showRelative?: boolean;
  showRequired?: boolean;
  required?: boolean;
  loading?: boolean;
  id?: string;
  emptyState?: string;
  toolTipText?: string;
  "data-testid"?: string;
  customButton?: ReactNode;
};

export const SelectInput = (props: PropsT) => {
  const {
    options,
    value = { value: "", label: "", description: "" },
    placeholder,
    disabled,
    wide,
    error,
    label,
    onChange,
    showRelative,
    hint,
    loading,
    id,
    emptyState,
    required,
    showRequired,
    "data-testid": dataTestId,
    customButton,
  } = props;

  const getSelectedText = () => {
    if (value?.label) {
      return <span className="block truncate">{value?.label}</span>;
    } else {
      return (
        <span className="block truncate text-sm text-gray-500">
          {placeholder || "Select..."}
        </span>
      );
    }
  };

  const idForInput = id || label || "select-input";

  return (
    <div
      className={clsx("SelectInput relative w-full", {
        "w-full md:w-80": !wide,
      })}
    >
      {hint && (
        <div className="mb-1 text-sm font-semibold text-gray-500">{hint}</div>
      )}

      {loading && <Loader label="Loading options..." className="mb-4" />}

      <Listbox
        id={idForInput}
        as="div"
        value={value}
        onChange={onChange}
        className="relative"
        data-testid={dataTestId || idForInput}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={clsx("group relative rounded-lg border", {
                "peer z-10 block w-full appearance-none overflow-ellipsis rounded-lg border border-gray-300 bg-white px-[15px] text-left text-sm text-gray-900 placeholder:text-transparent autofill:text-sm autofill:text-gray-900 focus:border-gray-900  focus:outline-none focus:ring-0":
                  !disabled || !error,
                "border-orange-600 !bg-orange-100": error,
                "pointer-events-none !border-gray-100 !bg-gray-100": disabled,
                "border-gray-900": open,
                "pb-2.5 pt-4 focus:pb-2.5 focus:pt-4": label,
                "pb-[13px] pt-[13px]": !label,
              })}
              data-testid={`${dataTestId || idForInput}-button`}
            >
              {getSelectedText()}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <Icon
                  size="20"
                  icon="caret-down"
                  iconStyle={clsx(
                    "transform fill-gray-400 transition-transform duration-500 group-focus:fill-gray-900",
                    {
                      "-rotate-180 fill-gray-900": open,
                    }
                  )}
                />
              </span>
            </Listbox.Button>
            <Listbox.Label
              className={clsx(
                "absolute left-4 z-10 cursor-text text-sm text-gray-500 duration-300",
                "origin-[0] -translate-y-3 scale-75 transform peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
                "transition-all",
                {
                  "top-3.5": label,
                  "top-2": !label,
                  "!cursor-default text-gray-500": disabled,
                }
              )}
            >
              {label} {showRequired && required ? "(required)" : ""}
              {showRequired && !required ? "(optional)" : ""}
            </Listbox.Label>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              className={clsx("absolute z-20 w-full", {
                "!relative": showRelative,
              })}
            >
              <Listbox.Options className="z-30 mt-1 max-h-60 min-w-fit overflow-y-scroll rounded-lg bg-white p-1 text-base shadow-lg outline-none">
                {customButton && customButton}

                {options && options.length > 0 ? (
                  options.map((option, idx) => (
                    <Listbox.Option
                      key={`${idx}-${option.value}`}
                      data-testid={`${dataTestId}-${idx}`}
                      className="mt-1 cursor-pointer"
                      value={option}
                    >
                      {({ active }) => {
                        return (
                          <div
                            className={clsx(
                              "flex items-center rounded-md py-2 pr-6",
                              {
                                "py-2 pr-6": !wide,
                                "bg-gray-50 ": active,
                                "bg-gray-100 text-sm font-medium text-gray-900":
                                  option.value === value?.value,
                                "pl-6 text-sm font-medium text-gray-500 md:pl-8":
                                  option.value !== value?.value,
                              }
                            )}
                          >
                            {option.value === value?.value && (
                              <Icon
                                icon="check"
                                size="16"
                                iconStyle="fill-gray-900 md:ml-4 ml-2"
                              />
                            )}
                            <div className="flex flex-col pl-2 pr-6 md:pl-3">
                              <span className="">{option.label}</span>
                              {option.description && (
                                <span className="text-xs font-normal text-gray-500">
                                  {option.description}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      }}
                    </Listbox.Option>
                  ))
                ) : (
                  <div className="px-4 py-2 text-sm font-medium text-gray-900">
                    {emptyState || "No items available"}
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>

      {error && (
        <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
          <Icon
            icon="warning-fill"
            size="16"
            iconStyle="fill-orange-600 mr-2"
          />
          {error}
        </div>
      )}
    </div>
  );
};
