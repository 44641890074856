import * as yup from "yup";

export const bankAccountSchema = yup.object().shape({
  account_number: yup
    .string()
    .matches(/^\d{7,12}$/, "Account number should only contain numbers")
    .min(7, "Account number should be 7-12 digits")
    .max(12, "Account number should be 7-12 digits")
    .required("Please enter your account number"),
  transit_number: yup
    .string()
    .matches(/^\d{5}$/, "Transit number should only contain numbers")
    .length(5, "Transit number should be 5 digits")
    .required("Please enter your transit number"),
  institution_number: yup
    .string()
    .matches(/^\d{3}$/, "Institution number should only contain numbers")
    .length(3, "Institution number should be 3 digits")
    .required("Please enter your institution number"),
  companyDocumentVoidCheque: yup
    .string()
    .required("Please upload a void cheque"),
});

export const creditCardSchema = yup.object({
  cardNumber: yup
    .string()
    .label("Card number")
    .min(18, "Card number needs to be at minimum 15 digits")
    .max(19, "Card number needs to be at most 16 digits")
    .required(),
  cvc: yup.string().label("CVC").min(3).max(4).required(),
  cardHolderName: yup.string().label("Name on card").required(),
  cardExpiryMonth: yup
    .number()
    .label("Expiry month")
    .transform((val, originalVal) => {
      return parseInt(originalVal);
    })
    .min(1)
    .max(12)
    .required(),
  cardExpiryYear: yup
    .number()
    .label("Expiry year")
    .transform((val, originalVal) => {
      return parseInt(originalVal);
    })
    .min(23)
    .required(),
  cardHolderPostalCode: yup.string().label("Postal code").min(1).required(),
  cardHolderAddress: yup.string().label("Address").min(1).required(),
});
