import { EntriesStatusT } from "../types";

export const getBuyerEPState = (
  sourceInvoice: InvoiceType,
  entity?: OrganizationalEntityT | null
): EntriesStatusT => {
  const paidAt = sourceInvoice.is_vendor_swap
    ? sourceInvoice.supplierInvoices?.[0].ep_paid_at
    : sourceInvoice.ep_paid_at;

  if (!paidAt) {
    return {
      state: "disabled",
      message: "Invoice has not been paid yet",
    };
  }

  const invoice = sourceInvoice.vendorSwappedInvoice
    ? sourceInvoice.vendorSwappedInvoice
    : sourceInvoice;

  if (
    (invoice.bill_clearing_payment_pushcommunicationid &&
      !invoice.bill_clearing_payment_erp_id) ||
    (invoice.buyer_pushcommunicationid && !invoice.buyer_erp_id)
  ) {
    return {
      state: "in-progress",
      message: "Processing",
    };
  }

  if (invoice.bill_clearing_payment_erp_id && invoice.buyer_erp_id) {
    return { state: "complete", message: "Complete" };
  }

  if (
    !entity?.bill_clearing_account_pushcommunicationid &&
    !entity?.bill_clearing_account_erp_id
  ) {
    return {
      state: "disabled",
      message:
        "Bill clearing account not set up. Please check the integration settings page",
    };
  }

  if (!entity?.quickly_vendor_erp_id && !entity?.vendor_pushcommunicationid) {
    return {
      state: "disabled",
      message:
        "Quickly vendor not set up. Please check the integration settings page",
    };
  }

  return { state: "ready" };
};
