"use client";

import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

export type PropsT = {
  title?: string;
  children: ReactNode;
  defaultOpen?: boolean;
  headerIcon?: string;
  titleStyles?: string;
  headerIconStyle?: string;
  className?: string;
  disabled?: boolean;
  disabledMessage?: string;
  "data-testid"?: string;
};

export const Accordion = (props: PropsT) => {
  const {
    title = "title",
    titleStyles = "",
    children,
    defaultOpen = false,
    headerIcon = "",
    headerIconStyle = "",
    className = "",
    disabled = false,
    disabledMessage = "",
    "data-testid": dataTestId = "Accordion",
  } = props;

  return (
    <Disclosure
      as="div"
      defaultOpen={defaultOpen}
      data-testid={dataTestId}
      className={clsx("w-full", {
        [className]: className,
      })}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            data-testid={`${dataTestId}-button`}
            className={clsx(
              "group flex w-full items-center justify-between rounded-t-lg border-l border-r border-t p-4",
              {
                "rounded-b-lg border-b transition-colors duration-500 hover:border-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900":
                  !open,
                "rounded-b-none border-b-transparent border-l-gray-500 border-r-gray-500 border-t-gray-500 transition-all duration-200 focus-visible:outline-none ":
                  open,
                "!hover:border-gray-200 !group-hover:border-gray-200 !border-gray-200 !text-gray-400 focus-visible:outline-none":
                  disabled,
                "hover:cursor-pointer": !disabled,
              }
            )}
            disabled={disabled}
          >
            <div className="flex items-center gap-x-2">
              {disabled && (
                <Tooltip title={disabledMessage} position="top">
                  <Icon
                    icon="warning-fill"
                    size="16"
                    iconStyle="fill-gray-400"
                  />
                </Tooltip>
              )}

              {headerIcon && (
                <Icon icon={headerIcon} iconStyle={headerIconStyle} />
              )}
              <div
                className={clsx(
                  "HeaderText flex items-center text-left text-sm font-medium transition-colors duration-500",
                  {
                    "text-gray-900 group-hover:text-gray-900": !disabled,
                    "text-gray-400 group-hover:text-gray-400": disabled,
                    "text-gray-900": open,
                    [titleStyles]: titleStyles,
                  }
                )}
              >
                {title}
              </div>
            </div>
            <Icon
              icon={"caret-down"}
              size="20"
              iconStyle={clsx(
                "fill-gray-500 transition-all duration-300 group-hover:fill-gray-900",
                {
                  "-rotate-90 transform": !open,
                  "fill-gray-900": open,
                  "fill-gray-400 group-hover:fill-gray-400": disabled,
                }
              )}
            />
          </Disclosure.Button>

          <Transition
            show={open}
            enter="duration-300 ease-out"
            enterFrom="opacity-0 -translate-y-6"
            enterTo="opacity-100 translate-y-0"
            leave="duration-300 ease-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-6"
          >
            <Disclosure.Panel
              static
              className={clsx(
                "-mt-2 origin-top rounded-b-lg border-b border-l border-r px-4 pb-4 pt-1 transition",
                {
                  "border-gray-500": open,
                }
              )}
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
